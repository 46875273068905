<template>
  <div class="navbar">
    <div class="nav">
      <el-menu mode="horizontal" :default-active="$route.path" :unique-opened="true" :router="true" text-color="#69696A"
        active-text-color="#3978E7">
        <el-menu-item index="/member">
          成员维护

        </el-menu-item>
        <el-menu-item index="/jurisdiction">
          角色权限维护
        </el-menu-item>
        <el-menu-item index="/procure" v-if="admin == '00000000-0000-0000-0000-000000000000'">
          采购组织维护
        </el-menu-item>
        <el-menu-item index="/toexamine" v-if="admin == '00000000-0000-0000-0000-000000000000'">
          审核
        </el-menu-item>
        <el-menu-item index="/Participants" v-if="admin == '00000000-0000-0000-0000-000000000000'">
          参研单位
        </el-menu-item>
        <!-- <el-menu-item index="/followUp">
          随访设置
        </el-menu-item> -->
      </el-menu>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import { session, local } from "@/utils/storage";
export default {
  data() {
    return {
      PurchaseId: session.get('PurchaseId'),
      Type: session.get('Type'),
      admin: local.get('UserId'),
    };
  },
  methods: {
  },
};
</script>
<style lang='scss' scoped>
.navbar {
  background-color: white;
  display: grid;

  .nav {
    padding: 10px 20px 0;
    box-sizing: border-box;

    .el-menu {
      background-color: transparent;
      border: 0;

      .el-menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
      }
    }
  }
}
</style>
